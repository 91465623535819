.logo {
    float: left;
    width: 300px;
    height: 31px;
    color: #ffffff;
}

.header-box, .menu-box {
    background-color: #196CA0 !important;
}

.ant-layout {
    background: unset !important;
}

.primary-text-bold {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 14px;
}

.form-label-custom {
    color: #0639A3;
    font-weight: bold;
    font-size: 14px;
}

.ant-form-item-label > label {
    color: #0639A3;
    font-weight: bold;
    font-size: 14px;
}

.ant-select {
    margin-bottom: 0 !important;
}

.form-content-container-content {
    position: relative;
    z-index: 2;
    padding: 10px 20px;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(250, 250, 250, 0.65);
    transition-property: background-color, backdrop-filter, -webkit-backdrop-filter;
    left: 0;
    top: 20px;
    width: 100%;
    border-radius: 26px;
}