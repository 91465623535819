.ant-collapse-header {
    background-color: rgba(250, 250, 250, 0.5) !important;
}

.custom-tabs {
    display: flex;
}

.custom-tabs-left {
    min-width: 150px;
    display: flex;
    flex-direction: column;
    border-right: rgba(255, 255, 255, 0.65) 1px solid;
}

.custom-tabs-right {
    padding: 0 20px;
    flex: 1;
}

.custom-tabs-left-items {
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding-right: 8px;
    cursor: pointer;
    font-size: 16px;
}

.custom-tabs-left-items-selected {
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding-right: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #dc3545;
}

.custom-tabs-left-items-disabled {
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding-right: 8px;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    font-size: 16px;
}

.custom-tabs-left-items:hover {
    color: #dc3545;
}