.home-container {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 60%;
    height: 460px;
    z-index: 1;
    margin: 0 auto;
    border: 3px solid #A6A4BA;
    border-radius: 20px;
    color: #FFFFFF;
}

.home-container-bg {
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(0, 0, 0, 0.65);
    transition-property: background-color, backdrop-filter, -webkit-backdrop-filter;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 16px;
}

.home-container-content {
    padding: 60px;
    position: relative;
    z-index: 2;
}

.welcome {
    color: #FFFFFF;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.2;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}