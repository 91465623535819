.login-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 580px;
    height: 400px;
    z-index: 1;
    margin: auto;
    border: 3px solid #A6A4BA;
    border-radius: 50px;
    color: #FFFFFF;
}

.login-box-bg {
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(0, 0, 0, 0.12);
    transition-property: background-color, backdrop-filter, -webkit-backdrop-filter;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 46px;
}

.login-box-content {
    padding: 60px;
    position: relative;
    z-index: 2;
    height: 100%;
}

.form-login-title {
    text-align: center;
    color: #FFFFFF;
}

.form-login-language, .ant-select {
    width: 100%;
    margin-bottom: 14px;
}

.version-context {
    position: absolute;
    bottom: 1rem;
    right: 4rem;
    color: #545b62;
    font-size: 12px;
}

.login-form-button {
    width: 100%;
}