.upgrade-container{
    max-width: 1080px;
    margin: 10px auto 10px auto;
    min-height: 720px;
    background-color: rgba(250, 250, 250, 0.65);
    border-radius: 20px;
    padding: 20px;
}
.upgrade-select{
    width: 300px!important;
    margin-bottom: 10px!important;
}
.upgrade-table-title{
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.upgrade-table-text{
    font-size: 18px;
    font-weight: bold;
}